<template>
  <div class="company-about modal-events" v-bind="onVideo">
    <div class="modal" v-if="info">
      <div class="container centered">
        <div class="header">
          <div>
            <span class="title primary-color event-title" bold>{{ post.title }}</span>
          </div>
          <div>
            <router-link :to="prevRoute.path ? prevRoute.path : '/'" class="button">
              <i class="fa fa-times"></i>
            </router-link>
          </div>
        </div>
        <div class="content">
          <div class="picture">
            <video class="homeVideo" width="100%" playsinline :muted="muted" controls autoplay>
              <source :src="post.mediaLink" type="video/mp4" />
            </video>
            <div class="picture-description">
              <p>{{ post.describe }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "../../store/index.js";
  import router from "../../router";
  export default {
    data: function () {
      return {
        prevRoute: {},
        info: null,
        locale: null,
      };
    },
    metaInfo: function () {
      const title = this.locale ? "- " + this.locale.watch.description : "";
      return {
        title: `Models1A ${title}`,
      };
    },
    computed: {
      onVideo: async function () {
        await this.getLang();
        this.startVideo();
      },
      post() {
        if (!this.info) {
          return {};
        }
        return this.info.news.news.find(({ name }) => name === this.$route.query.datapost);
      },
      muted() {
        if (!this.prevRoute?.matched?.length) {
          return true;
        }

        return this.$screen.width < 1024;
      },
    },
    methods: {
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          this.info = await store.getters["dataLang"];
          this.locale = await store.getters["localeLang"];
        }
      },
      startVideo: function () {
        const mbody = document.querySelector("body");
        mbody.classList.add("onvideo");
      },
      goBack: function () {
        if (this.$route.query.backto) {
          router.push(this.$route.query.backto);
        } else {
          router.go(-1);
        }
      },
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from;
      });
    },
    beforeRouteLeave(to, from, next) {
      const mbody = document.querySelector("body");
      mbody.classList.remove("onvideo");
      next();
    },
    mounted() {
      if (!this.$route.query.datapost) {
        router.push("/");
      }
      window.addEventListener("keydown", function (event) {
        if (event.code === "Escape" || event.keyUp === 27) {
          router.push("/");
        }
      });
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .modal-events {
    .modal {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.88);
      z-index: 999;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      .container {
        position: absolute;
        background: $white;
        max-width: 1024px;
        max-height: calc(100% - 60px);
        width: 100%;
        height: auto;
        border-radius: $mradius;
        overflow: hidden;
        .header {
          height: 60px;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: $white;
          .button {
            padding: $mpadding;
          }
        }
        .content {
          max-height: calc(100% - 60px);
          padding: $mpadding;
          margin-top: 60px;
          .text {
            margin-top: $mpadding/2;
          }
          .picture {
            img {
              max-width: 100%;
            }
            .picture-description {
              height: 200px;
              overflow-y: auto;
              p {
                white-space: pre-wrap;
              }
            }
            .homeVideo {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1440px) {
      .modal {
        .container {
          width: 750px;
        }
      }
    }
  }
</style>
